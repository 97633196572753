var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "maintenance-table" }, [
    _c("table", [
      _vm._m(0),
      _c(
        "tbody",
        _vm._l(_vm.tableData, function(item) {
          return _c(
            "tr",
            { key: item.id },
            [
              _c("td", [_vm._v(_vm._s(item.id))]),
              _c("td", [_vm._v(_vm._s(item.system))]),
              _c("td", [_vm._v(_vm._s(item.equipment))]),
              _c("td", {
                staticClass: "left-align",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.getFormattedMaintenanceContent(item.maintenanceContent)
                  )
                }
              }),
              _c("td", [_vm._v(_vm._s(item.keyPoints))]),
              _c("td", [_vm._v(_vm._s(item.maintenanceCycle))]),
              _vm._l(_vm.months, function(month) {
                return _c("td", [
                  _c("input", {
                    attrs: { type: "checkbox", disabled: "" },
                    domProps: { checked: item[month] }
                  })
                ])
              })
            ],
            2
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("序号")]),
        _c("th", [_vm._v("系统")]),
        _c("th", [_vm._v("设备名称")]),
        _c("th", [_vm._v("维护内容")]),
        _c("th", [_vm._v("维护关键点")]),
        _c("th", [_vm._v("维护周期")]),
        _c("th", [_vm._v("1月")]),
        _c("th", [_vm._v("2月")]),
        _c("th", [_vm._v("3月")]),
        _c("th", [_vm._v("4月")]),
        _c("th", [_vm._v("5月")]),
        _c("th", [_vm._v("6月")]),
        _c("th", [_vm._v("7月")]),
        _c("th", [_vm._v("8月")]),
        _c("th", [_vm._v("9月")]),
        _c("th", [_vm._v("10月")]),
        _c("th", [_vm._v("11月")]),
        _c("th", [_vm._v("12月")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }