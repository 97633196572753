<template>
    <div class="maintenance-table">
        <table>
            <thead>
                <tr>
                    <th>序号</th>
                    <th>系统</th>
                    <th>设备名称</th>
                    <th>维护内容</th>
                    <th>维护关键点</th>
                    <th>维护周期</th>
                    <th>1月</th>
                    <th>2月</th>
                    <th>3月</th>
                    <th>4月</th>
                    <th>5月</th>
                    <th>6月</th>
                    <th>7月</th>
                    <th>8月</th>
                    <th>9月</th>
                    <th>10月</th>
                    <th>11月</th>
                    <th>12月</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in tableData" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.system }}</td>
                    <td>{{ item.equipment }}</td>
                    <td class="left-align" v-html="getFormattedMaintenanceContent(item.maintenanceContent)"></td>
                    <td>{{ item.keyPoints }}</td>
                    <td>{{ item.maintenanceCycle }}</td>
                    <td v-for="month in months">
                        <input type="checkbox" :checked="item[month]" disabled />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [
                {
                    "id": 1,
                    "system": "配电及柴发系统",
                    "equipment": "高低压设备",
                    "maintenanceContent": "1、高压市电切换测试\n2、低压线路切换测试\n3、ATS自动切换测试\n4、市电全停柴发自启测试",
                    "keyPoints": "系统切换逻辑和柴发自启逻辑是否正常",
                    "maintenanceCycle": " 每年",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                },
                {
                    "id": 2,
                    "system": "配电及柴发系统",
                    "equipment": "高低压设备",
                    "maintenanceContent": "1、清检各汇流排及母线\n2、指示灯告警信号\n3、接触器开关接触\n4、功率因素在0.9以上",
                    "keyPoints": "外观无异常，信号正常，接触良好",
                    "maintenanceCycle": "每月",
                    "1月": true,
                    "2月": true,
                    "3月": true,
                    "4月": true,
                    "5月": true,
                    "6月": true,
                    "7月": true,
                    "8月": true,
                    "9月": true,
                    "10月": true,
                    "11月": true,
                    "12月": true
                },
                {
                    "id": 3,
                    "system": "配电及柴发系统",
                    "equipment": "高低压设备",
                    "maintenanceContent": "1、低压热成像仪检测",
                    "keyPoints": " 发热点检查 （热成像拍照）            ",
                    "maintenanceCycle": " 每季度",
                    "1月": false,
                    "2月": false,
                    "3月": true,
                    "4月": false,
                    "5月": false,
                    "6月": true,
                    "7月": false,
                    "8月": false,
                    "9月": true,
                    "10月": false,
                    "11月": false,
                    "12月": true
                },
                {
                    "id": 4,
                    "system": "配电及柴发系统",
                    "equipment": "柴油发电机",
                    "maintenanceContent": "1、检查机油位、冷却水位、及电池液位是否正常\n2、检查机组部件是否紧固\n3、检查机组是否有漏油、漏水\n4、每台机组空载5分钟",
                    "keyPoints": "必须进行运行前与运行后的检查",
                    "maintenanceCycle": "每月",
                    "1月": true,
                    "2月": true,
                    "3月": true,
                    "4月": true,
                    "5月": true,
                    "6月": true,
                    "7月": true,
                    "8月": true,
                    "9月": true,
                    "10月": true,
                    "11月": true,
                    "12月": true
                },
                {
                    "id": 5,
                    "system": "配电及柴发系统",
                    "equipment": "柴油发电机",
                    "maintenanceContent": "1、检查机油位、冷却水位、及电池液位是否正常\n2、检查机组部件是否紧固\n3、检查机组是否有漏油、漏水\n4、每台机组空载5-10分钟\n5、开关元件检查",
                    "keyPoints": "水足无沉淀，机油足粘度好，启动正常\n运行前、后都需要检查各项参数",
                    "maintenanceCycle": "每月",
                    "1月": true,
                    "2月": true,
                    "3月": true,
                    "4月": true,
                    "5月": true,
                    "6月": true,
                    "7月": true,
                    "8月": true,
                    "9月": true,
                    "10月": true,
                    "11月": true,
                    "12月": true
                },
                {
                    "id": 6,
                    "system": "配电及柴发系统",
                    "equipment": "柴油发电机",
                    "maintenanceContent": "6、柴发假负载带载测试",
                    "keyPoints": "柴发带载运行是否正常",
                    "maintenanceCycle": "  每季度",
                    "1月": false,
                    "2月": false,
                    "3月": true,
                    "4月": false,
                    "5月": false,
                    "6月": true,
                    "7月": false,
                    "8月": false,
                    "9月": true,
                    "10月": false,
                    "11月": false,
                    "12月": true
                },
                {
                    "id": 7,
                    "system": "配电及柴发系统",
                    "equipment": "UPS主机及电池",
                    "maintenanceContent": "对UPS进行检查、运行参数分析、电池自检参数",
                    "keyPoints": "各参数均符合标准",
                    "maintenanceCycle": "  每季度",
                    "1月": false,
                    "2月": false,
                    "3月": true,
                    "4月": false,
                    "5月": false,
                    "6月": true,
                    "7月": false,
                    "8月": false,
                    "9月": true,
                    "10月": false,
                    "11月": false,
                    "12月": true
                },
                {
                    "id": 8,
                    "system": "配电及柴发系统",
                    "equipment": "UPS主机及电池",
                    "maintenanceContent": "电池内阻测试",
                    "keyPoints": "内阻符合标准",
                    "maintenanceCycle": "  每季度",
                    "1月": false,
                    "2月": false,
                    "3月": true,
                    "4月": false,
                    "5月": false,
                    "6月": true,
                    "7月": false,
                    "8月": false,
                    "9月": true,
                    "10月": false,
                    "11月": false,
                    "12月": true
                },
                {
                    "id": 9,
                    "system": "配电及柴发系统",
                    "equipment": "UPS主机及电池",
                    "maintenanceContent": "1、UPS功能测试，逆变器整流器启停，电池管理功能\n2、检查主机、电池、配电部分接触情况，并测量温升和压降",
                    "keyPoints": "性能良好，输出正常\n连接良好，温升正常",
                    "maintenanceCycle": "每年",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                },
                {
                    "id": 10,
                    "system": "配电及柴发系统",
                    "equipment": "UPS主机及电池",
                    "maintenanceContent": "1、核对性容量试验\n2、蓄电池容量测试\n3、负荷均分系统单机运行测试，热备份系统负荷切换测试\n4、除尘清洁",
                    "keyPoints": "电池容量达到要求\n放电容量达到要求\n系统性能正常",
                    "maintenanceCycle": "每年",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                },
                {
                    "id": 11,
                    "system": "配电及柴发系统",
                    "equipment": "低压末端配电箱保养",
                    "maintenanceContent": "低压末端配电箱保养",
                    "keyPoints": "确保配电箱各设备性能正常",
                    "maintenanceCycle": "每年",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                },
                {
                    "id": 12,
                    "system": "配电及柴发系统",
                    "equipment": "防雷接地电阻测试",
                    "maintenanceContent": "防雷接地电阻测试",
                    "keyPoints": "确保配电箱各设备性能正常",
                    "maintenanceCycle": "每年",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                },
                {
                    "id": 13,
                    "system": "空调系统",
                    "equipment": ", 离心式冷水机",
                    "maintenanceContent": "检查有无漏油 缺油 漏气 漏水现象，保持主机无灰尘 无锈斑",
                    "keyPoints": "注意机器的数据传感器和开关阀门、各类运行参数分析",
                    "maintenanceCycle": "每月",
                    "1月": true,
                    "2月": true,
                    "3月": true,
                    "4月": true,
                    "5月": true,
                    "6月": true,
                    "7月": true,
                    "8月": true,
                    "9月": true,
                    "10月": true,
                    "11月": true,
                    "12月": true
                },
                {
                    "id": 14,
                    "system": "空调系统",
                    "equipment": ", 离心式冷水机",
                    "maintenanceContent": "告警系统, 电气工作系统",
                    "keyPoints": "厂家月度巡检",
                    "maintenanceCycle": "每月",
                    "1月": true,
                    "2月": true,
                    "3月": true,
                    "4月": true,
                    "5月": true,
                    "6月": true,
                    "7月": true,
                    "8月": true,
                    "9月": true,
                    "10月": true,
                    "11月": true,
                    "12月": true
                },
                {
                    "id": 15,
                    "system": "空调系统",
                    "equipment": ", 离心式冷水机",
                    "maintenanceContent": "主机轴承维护",
                    "keyPoints": "定期加注润滑油",
                    "maintenanceCycle": "每年",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                },
                {
                    "id": 16,
                    "system": "空调系统",
                    "equipment": "变频器",
                    "maintenanceContent": "变频器参数",
                    "keyPoints": "输入输出是否三相电流平衡",
                    "maintenanceCycle": "  每季度",
                    "1月": false,
                    "2月": false,
                    "3月": true,
                    "4月": false,
                    "5月": false,
                    "6月": true,
                    "7月": false,
                    "8月": false,
                    "9月": true,
                    "10月": false,
                    "11月": false,
                    "12月": true
                },
                {
                    "id": 17,
                    "system": "空调系统",
                    "equipment": "变频器",
                    "maintenanceContent": "变频器柜，温度，运行检查",
                    "keyPoints": "指示灯，柜内温度，数据，散热风扇",
                    "maintenanceCycle": "每月",
                    "1月": true,
                    "2月": true,
                    "3月": true,
                    "4月": true,
                    "5月": true,
                    "6月": true,
                    "7月": true,
                    "8月": true,
                    "9月": true,
                    "10月": true,
                    "11月": true,
                    "12月": true
                },
                {
                    "id": 18,
                    "system": "空调系统",
                    "equipment": "冷冻/冷却泵",
                    "maintenanceContent": "电机、联轴器、叶轮、机械密封检查",
                    "keyPoints": "除尘及轴承加润滑脂、油漆",
                    "maintenanceCycle": "每半年",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": true,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                },
                {
                    "id": 19,
                    "system": "空调系统",
                    "equipment": "空调膨胀水箱",
                    "maintenanceContent": "进水浮球灵活、水位正常",
                    "keyPoints": "浮球阀检查",
                    "maintenanceCycle": "  每季度",
                    "1月": false,
                    "2月": false,
                    "3月": true,
                    "4月": false,
                    "5月": false,
                    "6月": true,
                    "7月": false,
                    "8月": false,
                    "9月": true,
                    "10月": false,
                    "11月": false,
                    "12月": true
                },
                {
                    "id": 20,
                    "system": "空调系统",
                    "equipment": "冷却塔",
                    "maintenanceContent": "无漏水、漂水，管道无漏点",
                    "keyPoints": "定期清洗",
                    "maintenanceCycle": "每月",
                    "1月": true,
                    "2月": true,
                    "3月": true,
                    "4月": true,
                    "5月": true,
                    "6月": true,
                    "7月": true,
                    "8月": true,
                    "9月": true,
                    "10月": true,
                    "11月": true,
                    "12月": true
                },
                {
                    "id": 21,
                    "system": "空调系统",
                    "equipment": "冷却塔",
                    "maintenanceContent": "风机皮带、补水浮球阀检查",
                    "keyPoints": "定期检查",
                    "maintenanceCycle": "每月",
                    "1月": true,
                    "2月": true,
                    "3月": true,
                    "4月": true,
                    "5月": true,
                    "6月": true,
                    "7月": true,
                    "8月": true,
                    "9月": true,
                    "10月": true,
                    "11月": true,
                    "12月": true
                },
                {
                    "id": 22,
                    "system": "空调系统",
                    "equipment": "冷却塔",
                    "maintenanceContent": "根据第三方维保合同内容",
                    "keyPoints": "进行冷却塔清洗",
                    "maintenanceCycle": "每月",
                    "1月": true,
                    "2月": true,
                    "3月": true,
                    "4月": true,
                    "5月": true,
                    "6月": true,
                    "7月": true,
                    "8月": true,
                    "9月": true,
                    "10月": true,
                    "11月": true,
                    "12月": true
                },
                {
                    "id": 23,
                    "system": "空调系统",
                    "equipment": "冷却塔",
                    "maintenanceContent": "冷却塔风扇电机变速箱无异响",
                    "keyPoints": "风机变速箱检查、轴承加注润滑油",
                    "maintenanceCycle": " 每半年",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": true,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                },
                {
                    "id": 24,
                    "system": "空调系统",
                    "equipment": "新风机/空调, 风柜/加湿器",
                    "maintenanceContent": "清洗（或更换）过滤网、润滑油加注",
                    "keyPoints": "检查相关接水盘及排水管",
                    "maintenanceCycle": "  每季度",
                    "1月": false,
                    "2月": false,
                    "3月": true,
                    "4月": false,
                    "5月": false,
                    "6月": true,
                    "7月": false,
                    "8月": false,
                    "9月": true,
                    "10月": false,
                    "11月": false,
                    "12月": true
                },
                {
                    "id": 25,
                    "system": "空调系统",
                    "equipment": "精密空调",
                    "maintenanceContent": "清洗（或更换）过滤网",
                    "keyPoints": "清洗过滤网、过滤效果好",
                    "maintenanceCycle": "每半年",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": true,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                },
                {
                    "id": 26,
                    "system": "空调系统",
                    "equipment": "精密空调",
                    "maintenanceContent": "电器控制盘检查，各种电气性能检测，仪表校验",
                    "keyPoints": "性能良好，仪表准确",
                    "maintenanceCycle": "每年",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                },
                {
                    "id": 27,
                    "system": "空调系统",
                    "equipment": "精密空调",
                    "maintenanceContent": "检查电源控制箱、水管保温、管道接口及进出水管道是否完好，",
                    "keyPoints": "电源标识正确、接口无裂纹，无漏水",
                    "maintenanceCycle": "每月",
                    "1月": true,
                    "2月": true,
                    "3月": true,
                    "4月": true,
                    "5月": true,
                    "6月": true,
                    "7月": true,
                    "8月": true,
                    "9月": true,
                    "10月": true,
                    "11月": true,
                    "12月": true
                },
                {
                    "id": 28,
                    "system": "空调系统",
                    "equipment": "冷冻/冷却水系统",
                    "maintenanceContent": "根据第三方维保合同内容",
                    "keyPoints": "投入适量药剂，清除藻类，微生物",
                    "maintenanceCycle": "每月",
                    "1月": true,
                    "2月": true,
                    "3月": true,
                    "4月": true,
                    "5月": true,
                    "6月": true,
                    "7月": true,
                    "8月": true,
                    "9月": true,
                    "10月": true,
                    "11月": true,
                    "12月": true
                },
                {
                    "id": 29,
                    "system": "空调系统",
                    "equipment": "冷水系统阀门",
                    "maintenanceContent": "阀门加注润滑油保养",
                    "keyPoints": "阀门无卡死现象",
                    "maintenanceCycle": "每年",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                },
                {
                    "id": 30,
                    "system": "空调系统",
                    "equipment": "补水泵",
                    "maintenanceContent": "密封性检查，控制箱检查",
                    "keyPoints": "水泵无渗漏，控制箱无异常",
                    "maintenanceCycle": "  每季度",
                    "1月": false,
                    "2月": false,
                    "3月": true,
                    "4月": false,
                    "5月": false,
                    "6月": true,
                    "7月": false,
                    "8月": false,
                    "9月": true,
                    "10月": false,
                    "11月": false,
                    "12月": true
                },
                {
                    "id": 31,
                    "system": "空调系统",
                    "equipment": "补水泵",
                    "maintenanceContent": "电机、联轴器、叶轮、机械密封检查，过滤器清洗",
                    "keyPoints": "过滤器清洗，电机轴承润滑脂加注",
                    "maintenanceCycle": "每年",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                }
            ],
            months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        };
    },
    methods: {
        getFormattedMaintenanceContent(item) {
            return item.replace(/\n/g, '<br>');
        }
    }
};
</script>

<style scoped>
.maintenance-table {
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
}

td.left-align {
    text-align: left;
}
</style>